export const lineVarians = {
  hidden: {
    scaleY: 0,
  },
  hover: {
    scaleY: 1,
  },
};

export const textVariants = {
  hidden: {
    opacity: 0,
    y: '-70%',
  },
  hover: {
    opacity: 1,
    y: 0,
  },
};

export const imageVariants = {
  hidden: {
    scale: 1,
  },
  hover: {
    scale: 1.05,
  },
};
