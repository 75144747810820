import React, { FC } from 'react';

import { CategoriesSwiperItem } from 'components/Atoms/CategoriesSwiperItem/CategoriesSwiperItem';
import { useContent } from 'providers/ContentProvider/ContentProvider';
import { Wrapper, StyledSwiper, Item } from './CategoriesSwiper.style';
import type { CategoryElement } from 'types';
import { childVariants } from './variants';

export const CategoriesSwiper: FC = () => {
  const { categories } = useContent();

  return (
    <Wrapper>
      <StyledSwiper slidesPerView="auto">
        {categories.map(
          ({ id, categoryMainImage, title, slug }: CategoryElement, index: number) => (
            <Item
              custom={index}
              key={id}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={childVariants}
            >
              <CategoriesSwiperItem image={categoryMainImage} title={title} slug={slug} />
            </Item>
          ),
        )}
      </StyledSwiper>
    </Wrapper>
  );
};
