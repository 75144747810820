import styled from 'styled-components';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';

import { CTA } from 'components/Atoms/CTA/CTA';

export const Image = styled(GatsbyImage)`
  display: block;
  height: 100%;
  width: 100%;
`;

export const StyledCTA = styled(CTA)`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Overflow = styled.div`
  height: 100%;
  width: 100%;
`;

export const ImageWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const Wrapper = styled(motion.article)`
  width: 100%;
  height: 100%;
`;

export const Box = styled.div`
  position: absolute;
  left: 0;
  bottom: -5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Line = styled(motion.span)`
  display: block;
  width: 0.2rem;
  min-height: 5rem;
  transform-origin: top;
  background-color: ${({ theme }) => theme.colors.backgroundLight};
`;

export const TextWrapper = styled(motion.div)`
  padding-top: 0.5rem;
`;
