import React, { FC } from 'react';

import { Text } from 'components/Atoms/Text/Text';
import {
  Wrapper,
  StyledCTA,
  Overflow,
  ImageWrapper,
  Image,
  Box,
  Line,
  TextWrapper,
} from './CategoriesSwiperItem.style';
import { lineVarians, textVariants, imageVariants } from './variants';
import type { MainImage } from 'types';

interface CategoriesSwiperItemProps {
  image: MainImage;
  title: string;
  slug: string;
}

export const CategoriesSwiperItem: FC<CategoriesSwiperItemProps> = ({ image, title, slug }) => {
  return (
    <Wrapper initial="hidden" whileHover="hover">
      <StyledCTA to={`/${slug}`} draggable={false}>
        <Overflow>
          <ImageWrapper variants={imageVariants}>
            <Image image={image.gatsbyImageData} alt={image.alt} />
          </ImageWrapper>
        </Overflow>
        <Box>
          <Line variants={lineVarians} />
          <TextWrapper variants={textVariants}>
            <Text fontWeight="bold" fontSize="1.2rem" isLower letterSpacing="2px">
              {title}
            </Text>
          </TextWrapper>
        </Box>
      </StyledCTA>
    </Wrapper>
  );
};
