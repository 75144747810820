import { graphql } from 'gatsby';
import React from 'react';

import { Categories } from 'components/Organisms/Categories/Categories';
import { ContentProvider } from 'providers/ContentProvider/ContentProvider';
import { SEO } from 'components/Organisms/SEO/SEO';

import type { CategoriesPage } from 'types';
interface CategoriesTemplateProps {
  data: CategoriesPage;
}

const CategoriesTemplate: React.FC<CategoriesTemplateProps> = ({ data }) => {
  const { seo, locale, metakeywords } = data.categoryPage;

  return (
    <>
      <SEO seo={seo} lang={locale} keywords={metakeywords} />
      <ContentProvider data={data}>
        <Categories />
      </ContentProvider>
    </>
  );
};

export default CategoriesTemplate;

export const query = graphql`
  query CATEGORIES_PAGE_QUERY($locale: String!) {
    socialIcons: allDatoCmsSocial(filter: { locale: { eq: $locale } }) {
      nodes {
        ...SocialIcon
      }
    }
    categoryPage: datoCmsCategoriesPage(locale: { eq: $locale }) {
      locale
      metakeywords
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    categories: allDatoCmsCategory(filter: { locale: { eq: $locale } }, sort: { fields: order }) {
      nodes {
        ...CategoryElement
        ...CategoryMainImage
      }
    }
  }
`;
