import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Swiper } from 'components/Organisms/Swiper/Swiper';

export const Wrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  height: 100%;
  max-height: 100%;
  width: 100%;
  cursor: grab;
  padding-top: 10px;

  ${({ theme }) => theme.mq.phone} {
    padding-top: 40px;
  }

  ${({ theme }) => theme.mq.tablet} {
    padding-top: 60px;
  }
`;

export const StyledSwiper = styled(Swiper)`
  height: 100%;

  .swiper-slide {
    width: 120px !important;
    max-height: 80%;
    margin-right: 10px;

    ${({ theme }) => theme.mq.tablet} {
      width: 180px !important;
      margin-right: 30px;
    }

    ${({ theme }) => theme.mq.bigTablet} {
      width: 250px !important;
      margin-right: 40px;
    }
  }
`;

export const Item = styled(motion.li)`
  width: 100%;
  height: 100%;
`;
