import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.section)`
  max-width: ${({ theme }) => theme.layout.maxContainerWidth};
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 0 0 8rem;

  ${({ theme }) => theme.mq.bigTablet} {
    padding-left: 12rem;
  }
`;
