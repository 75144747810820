import React, { FC } from 'react';

import { CategoriesSwiper } from 'components/Molecules/CategoriesSwiper/CategoriesSwiper';
import { SideNav } from 'components/Molecules/SideNav/SideNav';
import { getCurrentNavItem } from 'helpers';
import { useTranslations } from 'hooks/useTranslations';
import { Wrapper } from './Categories.style';

export const Categories: FC = ({}) => {
  const { menu } = useTranslations();
  const [categoryItem] = getCurrentNavItem({ menu, elements: [2] });

  return (
    <>
      <Wrapper initial="hidden" animate="visible" exit="exit">
        <CategoriesSwiper />
      </Wrapper>
      <SideNav mainItem={categoryItem} withSubLink={false} />
    </>
  );
};
